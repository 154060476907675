import gql from 'graphql-tag';

export const TRAVEL_TIPS_ACTUAL = gql`
    query TravelTipsActualQuery($filter: TravelTipsFilter!, $pageSize: PageSize) {
        travelTipsActual(filter: $filter, pageSize: $pageSize) {
            id,
            titlePicture {
                fileName
            },
            theme,
            localizations {
                title
            }
            itineraryDays {
                itineraryItems {
                    countries {
                        nameEnglish
                        nameCzech
                    }
                    location {
                        latitude
                        longitude
                    }
                    transferTypeValues {
                        transferType
                    }
                },
            }
        }
    }`;

export const TRAVEL_TIPS_PINBOARD = gql`
    query TravelTipsActualQuery($filter: TravelTipsFilter, $pageSize: PageSize) {
        travelTipsActual(filter: $filter, pageSize: $pageSize) {
            id,
            titlePicture {
                fileName
            },
            localizations {
                title
            }
            itineraryDays {
                itineraryItems {
                    countries {
                        nameEnglish
                        nameCzech
                    }
                },
            }
        }
    }`;

export const TRAVEL_TIPS_ACTUAL_COUNT = gql`
    query TravelTipsActualCountQuery($filter: TravelTipsFilter!) {
        travelTipsActualCount(filter: $filter)
    }`