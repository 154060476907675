<template>
    <div>
      <p ref="textContainer" :style="{
        'line-height': lineHeightInPixels + 'px',
        'max-height': isTextOverflowed ? (lineHeightInPixels * amountOfVisibleLines + 'px') : 'unset'
      }">
        <slot></slot>
      </p>
      <button v-if="isTextOverflowed" @click="expandText" class="text-button">{{ $t('general.showMore')}}</button>
      <button v-else-if="hasAlreadyBeenExpanded" @click="shrinkText" class="text-button">{{ $t('general.showLess') }}</button>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      amountOfVisibleLines: {
        type: Number,
        required: false,
        default: 3
      },
      lineHeightInPixels: {
        type: Number,
        required: false,
        default: 26
      },
      windowResized: {
        type: Boolean,
        required: false,
      }
    },
    data() {
      return {
        hasAlreadyBeenExpanded: false,
        isTextOverflowed: false,
      };
    },
    mounted() {
        this.$nextTick(() => {
            this.truncateText();
        })
    },
    methods: {
      truncateText() {
        const container = this.$refs.textContainer;
        this.isTextOverflowed = container.scrollHeight > (this.lineHeightInPixels * this.amountOfVisibleLines);
      },
      expandText() {
        this.hasAlreadyBeenExpanded = true
        this.isTextOverflowed = false;
      },
      shrinkText() {
        this.hasAlreadyBeenExpanded = false
        this.isTextOverflowed = true
      }
    },
    watch: {
      windowResized(nV) {
        if (!this.hasAlreadyBeenExpanded) {
          this.truncateText()
        }
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
    @import '@/scss/variables';

    p {
      overflow: hidden;
      margin-bottom: 0;
      white-space: pre-line;
    }

    .text-button {
      border: none;
      background-color: transparent;
      color: $tr-green-alt-link;
      padding: 0;

      &:hover {
        text-decoration: underline;
      }
    }
  </style>
  