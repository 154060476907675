import gql from 'graphql-tag'

export const PINBOARD_POSTS = gql`
    query pinboardPostsByPinboardId($pinboardId: Int!) {
        pinboardPosts: pinboardPostsByPinboardId(pinboardId: $pinboardId) {
            id,
            author {
                id
                userName
            },
            pinboard {
                id
            },
            travelTip {
                id
                localizations {
                    title
                }
                titlePicture {
                    fileName
                }
                itineraryDays {
                    itineraryItems {
                        countries {
                            nameCzech
                        }
                    }
                }
            },
            createdAt,
            lastUpdatedAt,
            title,
            description,
            numberOfDaysMin,
            numberOfDaysMax,
            startDate,
            endDate
        }
    }`;