import gql from 'graphql-tag'

export const PINBOARDS = gql`
    query pinboards {
        pinboards {
            id,
            title,
            description
            general
        }
    }`;

export const PINBOARD_BY_ID = gql`
    query pinboard($pinboardId: Int!) {
        pinboard(pinboardId: $pinboardId) {
            id,
            title,
            description
            general
        }
    }`;