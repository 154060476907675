
import { apolloClient } from "@/api/apolloClient";
import {CREATE_PINBOARD_POST, UPDATE_PINBOARD_POST, DELETE_PINBOARD_POST} from "../api/graphql/mutation/PinboardPostMutation";

export default {
    createPinboardPost: function(form){
        console.log("Start to creating pinboard post: "+ form.title);
        const {pinboardId, travelTipId, title, description, numberOfDaysMin, numberOfDaysMax, startDate, endDate} = form;

        return apolloClient.mutate({
            mutation: CREATE_PINBOARD_POST,
            variables: {
                pinboardId,
                travelTipId,
                title,
                description,
                numberOfDaysMin,
                numberOfDaysMax,
                startDate,
                endDate
            },
        }).then((result) => {
            console.log("Pinboard post created: ", result);
            return result;
        });
    },

    updatePinboardPost: function(form){
      console.log("Start to updating pinboard post with ID "+ form.id);
      const {pinboardPostId, pinboardId, travelTipId, title, description, numberOfDaysMin, numberOfDaysMax, startDate, endDate} = form;
      return apolloClient.mutate({
          mutation: UPDATE_PINBOARD_POST,
          variables: {
            pinboardPostId,
            pinboardId,
            travelTipId,
            title,
            description,
            numberOfDaysMin,
            numberOfDaysMax,
            startDate,
            endDate
          },
      }).then((result) => {
          console.log("Pinboard post updated: ", result);
          return result;
      });
  },

  deletePinboardPost: function(pinboardPostId){
    console.log("Start to deleting pinboard post with pinboardPostId "+ pinboardPostId);
    return apolloClient.mutate({
        mutation: DELETE_PINBOARD_POST,
        variables: {
          pinboardPostId,
        },
    }).then((result) => {
        console.log("Pinboard post deleted: ", result);
        return result;
    });
},
}