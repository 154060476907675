<template>
    <div class="bg-white-alt">
        <NavBarNew :background='true'/>
        <main class="container text-left">
            <div class="pinboard">
                <div class="picture-background">
                    <img class="picture" src="@/assets/svg/trekio-logo-icon.svg" alt="">
                </div>
                <p class="title">{{ pinboard && pinboard.general ? $t("public.pinboardPostsPage.lookingForTravelBuddy") : '...' }}</p>
            </div>
            <div class="pinboard-info text-center mt-4 mb-2">
                <p>{{ $t('public.pinboardPostsPage.createPostWithInfo') }}</p> 
                <p>{{ $t('public.pinboardPostsPage.otherUsersCanReply') }}</p>
            </div>
            <div v-if="$apollo.queries.pinboardPosts.loading" class="text-center mt-5">
                <h5>{{ $t("loading.loading") }}</h5>
            </div>
            <div v-else class="posts-container">
                <p class="no-results" v-if="!pinboardPosts || pinboardPosts.length == 0">{{ $t('public.pinboardPostsPage.thereAreNoPosts') }}</p>
                <div v-else class="post" v-for="post in pinboardPosts" :key="post.id">
                    <div class="d-flex align-items-center">
                        <img v-if="post.author.avatarFileName" class="picture" :src="getAvatarFileUrl(post.author.avatarFileName)" alt="">
                        <div v-else class="picture">{{post.author.userName.split(' ').map(function(item){return item[0]}).join('').substring(0,3)}}</div>
                        <p class="username">{{ post.author.userName}}</p>
                        <p class="created-at">{{ formatDate(post.createdAt, true) }}</p>
                    </div>
                    <div class="main-content">
                        <p class="title">{{ post.title }}</p>
                        <p class="description">
                            <ExpandableTextComponent :amount-of-visible-lines="10">
                                {{ post.description }}
                            </ExpandableTextComponent>
                        </p>
                    </div>
                    <router-link v-if="post.travelTip" target="_blank" :to="{name: 'travelTipDetail', params: {travelTipId: post.travelTip.id}}" class="travel-tip">
                        <img class="travel-tip-picture" :src="post.travelTip && post.travelTip.titlePicture ? getPictureUrlLowResolution(post.travelTip.titlePicture.fileName): require('@/assets/svg/ManageTraveltipDumyImage.svg')" alt="">
                        <div class="overflow-hidden">
                            <p class="travel-tip-title">{{ post.travelTip.title }}</p>
                            <p class="travel-tip-countries">{{ getCountriesAsText(post.travelTip) }}</p>
                        </div>
                    </router-link>
                    <div class="additional-info-container">
                        <div class="d-flex">
                            <p class="day-range" v-if="post.numberOfDaysMax && post.numberOfDaysMin">{{ formatDayRange(post.numberOfDaysMin, post.numberOfDaysMax) }}</p>
                            <p class="date-range">{{ `${formatDate(post.startDate)} - ${formatDate(post.endDate, true)}` }}</p>

                        </div>
                        <div class="buttons d-flex">
                            <button v-if="canEdit(post.author.id)" class="img-button" @click="editPost(post)">
                                <img src="@/assets/svg/icon-edit-profile.svg" alt="">
                            </button>
                            <button v-if="canDelete(post.author.id)" class="img-button" @click="$bvModal.show('delete-post-modal' + post.id)">
                                <img src="@/assets/svg/icon-delete.svg" alt="">
                            </button>
                            <router-link v-if="!currentUserId" :to="{name:'login', query: {returnUrl: $router.currentRoute.path}}" 
                                v-tooltip.top="!currentUserId ? $t('info.loginToWriteAMessage') : null">
                                <img class="icon-send" src="@/assets/svg/icon-send.svg" alt="">
                            </router-link>
                            <router-link v-else-if="post.author.id != currentUserId" :to="{name:'messages', params:{newConversationPostId: post.id, post: post}}">
                                <img class="icon-send" src="@/assets/svg/icon-send.svg" alt="">
                            </router-link>
                        </div>
                    </div>
                    <b-modal class="modal" :id="`delete-post-modal${post.id}`" hide-footer hide-header no-close-on-backdrop no-fade>
                        <div class="delete-modal-container">
                            <h3 class="pb-3">{{ $t("modals.pinboardPostDeleteTitle", [post.title])}}</h3>
                            <h5 class="pb-3">{{ $t("modals.pinboardPostDeleteText") }}</h5>
                            <div class="buttons-container">
                                <TrekioButton secondary @click="$bvModal.hide('delete-post-modal' + post.id)">{{$t("general.back")}}</TrekioButton>
                                <TrekioButton primary :isLoading="loadingDelete != ''" :loadingText="loadingDelete" @click="onDeleteConfirmed(post.id)">{{ $t("buttons.delete")}}</TrekioButton>
                            </div>
                        </div>
                    </b-modal>
                </div>
            </div>
            <b-modal class="modal" :id="`edit-post-modal`" hide-footer hide-header white-bg no-fade>
                <form @submit.prevent="onSubmit" ref="form" novalidate>
                    <h3>{{ $t('public.pinboardPostsPage.findTravelBuddy') }}</h3>
                    <TrekioInput id="pinboardTitle" :label="$t('inputLabels.pinboardPostTitle')" trim v-model="form.title" maxlength="100" required
                        :error="error.title" :hint="$t('inputHints.maxLength', [100])"
                    />
                    <TrekioTextarea id="pinboardDescription" :label="$t('inputLabels.pinboardPostDescription')" trim v-model="form.description" maxlength="4096" required
                        :error="error.description" :hint="$t('inputHints.maxLength', [4096])"
                    />
                    <div class="day-slider">
                        <label>{{ $t("inputLabels.tripLength") }}</label>
                        <VueSlider
                            ref="slider"
                            v-model="slider.value"
                            v-bind="slider.options"
                            :tooltip="showMergeTooltip ? 'none' : 'always'"
                        >
                            <template v-slot:process="{start, end, style}">
                            <div class="vue-slider-process" :style="style">
                                <div v-if="showMergeTooltip" :class="[
                                'vue-slider-custom-merge-tooltip',
                                'vue-slider-dot-tooltip-bottom',
                                'vue-slider-dot-tooltip-inner'
                                ]">
                                {{ formatTooltip(slider.value[0]) }} - {{ formatTooltip(slider.value[1]) }}
                                </div>
                            </div>
                            </template>
                        </VueSlider>
                        <button type="button" class="img-button button-set-more-days" @click="setMoreDays"></button>
                    </div>
                    <div class="form-group">
                        <TrekioInput type="date" id="startDate" :label="$t('inputLabels.leaveEarliestOn')" trim v-model="form.startDate" required :error="error.startDate"/>
                        <TrekioInput type="date" id="endDate" :label="$t('inputLabels.returnLatestOn')" trim v-model="form.endDate" required :error="error.endDate"
                            :info="$t('info.pinboardEndDate')"
                        />
                    </div>

                    <div class="travel-tip-container">
                        <router-link v-if="chosenTravelTip" target="_blank" :to="{name: 'travelTipDetail', params: {travelTipId: chosenTravelTip.id}}" class="chosen-travel-tip">
                            <img class="travel-tip-picture" :src="chosenTravelTip.titlePicture ? getPictureUrlLowResolution(chosenTravelTip.titlePicture.fileName): require('@/assets/svg/ManageTraveltipDumyImage.svg')" alt="">
                            <div class="travel-tip-info">
                                <p class="travel-tip-title">{{ chosenTravelTip.title }}</p>
                                <p class="travel-tip-countries">{{ getCountriesAsText(chosenTravelTip) }}</p>
                            </div>
    
                        </router-link>
                        <button type="button" v-if="chosenTravelTip" class="text-button mr-3 mb-4" @click="removeTravelTip">{{ $t("buttons.deleteTrip") }}</button>
                        <button type="button" v-if="!isTravelTipListVisible" class="text-button mb-4" @click="showTravelTips">{{ chosenTravelTip ? $t("buttons.changeTrip") : $t("buttons.addTrekioTrip") }}</button>
                        <div v-if="isTravelTipListVisible" class="add-travel-tip-container">
                            <button class="img-button button-hide-list" @click="isTravelTipListVisible = false"><img src="@/assets/svg/icon-close.svg"/></button>
                            <p class="choose-travel-tip">{{ $t('public.pinboardPostsPage.chooseTripToYourPost') }}</p>
                            <input type="text" :placeholder="$t('public.pinboardPostsPage.searchByName')" v-model="filter.searchString">
                            <p v-if="$apollo.queries.travelTipsActual.loading" class="text-center">{{ $t("filter.searchingForTrips") }}</p>
                            <div v-else class="travel-tip-list">
                                <div class="travel-tip-card" v-for="travelTip in visibleTravelTips" :key="travelTip.id" @click="chooseTravelTip(travelTip)">
                                    <img class="travel-tip-picture" :src="travelTip.titlePicture ? getPictureUrlLowResolution(travelTip.titlePicture.fileName): require('@/assets/svg/ManageTraveltipDumyImage.svg')" alt="">
                                    <div class="travel-tip-info">
                                        <p class="travel-tip-title" :data-title="travelTip.title">{{ travelTip.title }}</p>
                                        <p class="travel-tip-countries">{{ getCountriesAsText(travelTip) }}</p>
                                    </div>
                                </div>
                            </div>
                            <p class="mb-0" v-if="filter.searchString && !filteredTravelTips.length">{{ $t("filter.noResults") }}</p>
                            <TrekioButton type="button" v-if="isMoreToShow" secondary class="show-more-button" @click="showMoreTravelTips">{{ $t("general.showMore") }}</TrekioButton>
                        </div>

                    </div>

                    <div class="buttons-container">
                        <TrekioButton type="button" secondary @click="$bvModal.hide('edit-post-modal')">{{$t("general.back")}}</TrekioButton>
                        <TrekioButton type="submit" :isLoading="loading != ''" :loadingText="loading" primary>{{ form.pinboardPostId ? $t("buttons.saveChanges") :  $t("buttons.addPinboardPost") }}</TrekioButton>
                    </div>
                </form>
            </b-modal>
            <button class="create-pinboard-post-button" v-tooltip.top="!currentUserId ? $t('public.pinboardPostsPage.loginToAddPost') : null" @click="showForm()">
                <img src="@/assets/svg/icon-write.svg" alt="">
            </button>
        </main>
        <Footer/>
    </div>
</template>

<script>
import Footer from '@/components/Footer'
import { PINBOARD_BY_ID } from '../api/graphql/query/PinboardQuery'
import { PINBOARD_POSTS } from '../api/graphql/query/PinboardPostQuery'
import PinboardPostService from '@/services/PinboardPostService'
import {CLEVERTRIP_API_URL}  from '@/definitions';
import sharedUtils from '@/utils/sharedUtils'
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import ExpandableTextComponent from '../components/ExpandableTextComponent.vue';
import {TRAVEL_TIPS_PINBOARD} from '../api/graphql/query/FilterQuery'
import {assignTravelTipLocalizationProperties } from '../utils/LocalizationDataConverter.js'

const VISIBLE_TRAVEL_TIPS_STEP = 8

    export default {
        name: "PinboardPostsPage",

        components: {
            Footer,
            VueSlider,
            ExpandableTextComponent
        },

        created() {
            // this.pinboardId = this.$route.params.pinboardId
            // this.form.pinboardId = this.$route.params.pinboardId
        },

        watch: {
            'slider.value'(nV) {
                this.form.numberOfDaysMin = nV[0];
                this.form.numberOfDaysMax = nV[1];
            },
            'filter.searchString'(nV) {
                this.ammountOfVisibleTravelTips = VISIBLE_TRAVEL_TIPS_STEP
            },
            visibleTravelTips(nV) {
                if (!this.filteredTravelTips) return
                this.isMoreToShow = this.filteredTravelTips.length > this.ammountOfVisibleTravelTips 
            }
        },

        data() {
            return {
                pinboardId: 1,
                imgUrl: CLEVERTRIP_API_URL + "/avatar/downloadFile/",
                loading: '',
                loadingDelete: '',
                slider: {
                    value: [1, 10],
                    options: {
                        dotSize: 40,
                        width: 'auto',
                        height: 2,
                        contained: false,
                        direction: 'ltr',
                        data: null,
                        dataLabel: 'label',
                        dataValue: 'value',
                        min: 1,
                        max: 10,
                        interval: 1,
                        disabled: false,
                        clickable: true,
                        duration: 0.5,
                        adsorb: false,
                        lazy: false,
                        tooltip: 'active',
                        tooltipPlacement: 'bottom',
                        tooltipFormatter: function (value) {
                            return this.$tc("general.dayAmmount", value)
                        },
                        useKeyboard: false,
                        keydownHook: null,
                        dragOnClick: false,
                        enableCross: true,
                        fixed: false,
                        minRange: void 1,
                        maxRange: void 10,
                        order: true,
                        marks: false,
                        dotOptions: void 0,
                        dotAttrs: void 0,
                        process: true,
                        dotStyle: void 0,
                        railStyle: void 0,
                        processStyle: void 0,
                        tooltipStyle: void 0,
                        stepStyle: void 0,
                        stepActiveStyle: void 0,
                        labelStyle: void 0,
                        labelActiveStyle: void 0,
                    },
                },
                error: {
                    title: '',
                    description: '',
                    startDate: '',
                    endDate: '',
                    numberOfDaysMin: '',
                    numberOfDaysMax: '',
                },
                isFormVisible: false,
                form: {
                    pinboardId: this.pinboardId,
                    pinboardPostId: null,
                    travelTipId: null,
                    title: "",
                    description: "",
                    numberOfDaysMin: 1,
                    numberOfDaysMax: 10,
                    startDate: null,
                    endDate: null,
                },
                filter: {
                    searchString: ''
                },
                chosenTravelTip: null,
                isTravelTipListVisible: false,
                ammountOfVisibleTravelTips: VISIBLE_TRAVEL_TIPS_STEP,
                isMoreToShow: true,
            }
        },

        methods: {
            setMoreDays() {
                if (this.slider.options.max == this.form.numberOfDaysMax) {
                    this.slider.options.max += 1
                }
                this.$nextTick(() => {
                    this.form.numberOfDaysMax += 1
                    this.slider.value = [this.slider.value[0], this.slider.value[1] += 1]
                })
            },
            showMoreTravelTips() {
                this.ammountOfVisibleTravelTips += VISIBLE_TRAVEL_TIPS_STEP
            },
            removeTravelTip() {
                this.form.travelTipId = null
                this.chosenTravelTip = null
            },
            getCountriesAsText(travelTip) {
                let countriesInTrip = []

                if (travelTip) {
                    if (!travelTip.itineraryDays) return []
                    travelTip.itineraryDays.forEach(day => {
                        day.itineraryItems.forEach(item => {
                        item.countries.forEach(country => {
                            if (!countriesInTrip.some(country => country.nameEnglish === country.nameEnglish)) {
                                countriesInTrip.push(country)
                            }
                        })
                        })
                    })
                }
                return countriesInTrip.map(country => country[this.countryLocalization]).join(', ')
            },
            chooseTravelTip(travelTip) {
                this.form.travelTipId = travelTip.id
                this.chosenTravelTip = travelTip
                this.isTravelTipListVisible = false
            },
            showTravelTips() {
                this.filter.searchString = ''
                this.isTravelTipListVisible = true
            },
            setEndDate() {
                if (!this.form.endDate) {
                    this.form.endDate = this.form.startDate
                }
            },
            blockInvalidChar(e) {
                let allowedSymbols = ['1', '2', '3', '4', '5' ,'6' ,'7' ,'8', '9' ,'0', 'Enter']
                if (!allowedSymbols.includes(e.key)) {
                    e.preventDefault()
                }
            },
            formatDayRange(min, max) {
                if (min === max) {
                    return this.$tc("general.dayAmmount", min)
                } else {
                    return min + ' - ' + this.$tc("general.dayAmmount", max)
                }

            },
            canEdit(authorId) {
                return authorId == this.currentUserId || this.currentUserRole == "ADMIN_SUPERVISOR"
            },
            canDelete(authorId) {
                return authorId == this.currentUserId || ["ADMIN", "ADMIN_SUPERVISOR"].includes(this.currentUserRole)
            },
            redirectToLogin() {
                this.$router.push({path: "/login", query: {returnUrl: this.$router.currentRoute.path}})
            },
            showForm() {
                if (!this.currentUserId) {
                    return this.redirectToLogin()
                }
                this.resetToDefault()
                this.$bvModal.show('edit-post-modal')
            },
            getAvatarFileUrl(avatarFileName) {
                return sharedUtils.getPictureUrlLowResolution(avatarFileName, this.imgURL)
            },
            getPictureUrlLowResolution(fileName) {
                return sharedUtils.getPictureUrlLowResolution(fileName)
            },
            editPost(post) {
                let moment = require('moment')
                this.resetToDefault()
                this.form = {
                    pinboardPostId: post.id,
                    travelTipId: post.travelTip?.id,
                    title: post.title,
                    description: post.description,
                    numberOfDaysMin: post.numberOfDaysMin,
                    numberOfDaysMax: post.numberOfDaysMax,
                    startDate: moment(post.startDate).format('YYYY-MM-DD'),
                    endDate: moment(post.endDate).format('YYYY-MM-DD'),
                }
                this.chosenTravelTip = post.travelTip
                this.$bvModal.show('edit-post-modal')
            },
            async onDeleteConfirmed(id) {
                this.loadingDelete = this.$t("loading.pinboardPostDelete")
                await PinboardPostService.deletePinboardPost(id)
                .then(resp => {
                    this.$apollo.queries.pinboardPosts.refetch({
                        pinboardId: this.pinboardId
                    })
                    console.log(resp)
                })  
                this.loadingDelete = ''
            },
            removeErrors() {
                for (let errorName in this.error) {
                    this.error[errorName] = '';
                }
            },
            validateInputs() {
                let errorCount = 0
                this.removeErrors()

                const currentDate = new Date()
                currentDate.setHours(0, 0, 0, 0);
                
                if (!this.form.startDate) {
                    errorCount += 1
                    this.error.startDate = this.$t("inputErrors.required")
                } else if (new Date(this.form.startDate) < currentDate) {
                    errorCount += 1
                    this.error.startDate = this.$t("inputErrors.dateMustNotBeInPast")
                }

                if (!this.form.endDate) {
                    errorCount += 1
                    this.error.endDate = this.$t("inputErrors.required")
                } else if (new Date(this.form.endDate) < currentDate) {
                    errorCount += 1
                    this.error.endDate = this.$t("inputErrors.dateMustNotBeInPast")
                }
                else if (this.form.endDate < this.form.startDate) {
                    errorCount += 1
                    this.error.endDate = this.$t("inputErrors.endDateBeforeStartDate")
                }
                else if (this.form.numberOfDaysMin && this.form.numberOfDaysMax) {
                    const moment = require('moment')
                    const startMoment = moment(this.form.startDate);
                    const endMoment = moment(this.form.endDate);
    
                    const differenceInDays = endMoment.diff(startMoment, 'days') + 1;
    
                    if (differenceInDays < this.form.numberOfDaysMin) {
                        errorCount += 1;
                        this.error.endDate = this.$t("inputErrors.dateRangeIsTooSmall")
                    }
                }

                if (!this.form.title) {
                    errorCount += 1
                    this.error.title = this.$t("inputErrors.required")
                }
                if (!this.form.description) {
                    errorCount += 1
                    this.error.description = this.$t("inputErrors.required")
                }
                return errorCount == 0 ? true : false
            },
            async onSubmit() {
                if (!this.validateInputs()) return
                if (!this.form.pinboardPostId) {
                    this.loading = this.$t("loading.pinboardPostCreate")
                    await PinboardPostService.createPinboardPost(this.form)
                    .then( async (resp) => {
                        console.log(resp)
                        await this.$apollo.queries.pinboardPosts.refetch({
                                pinboardId: this.pinboardId
                            })
                            this.loading = ''
                            this.$bvModal.hide('edit-post-modal')
                            this.resetToDefault()
                        })
                        .catch(err => {
                            console.log(err)
                            this.loading = ''
                        })
                    } else {
                    this.loading = this.$t("loading.pinboardPostUpdate")
                    await PinboardPostService.updatePinboardPost(this.form)
                        .then( async (resp) => {
                            console.log(resp)
                            await this.$apollo.queries.pinboardPosts.refetch({
                                pinboardId: this.pinboardId
                            })
                            this.loading = ''
                            this.$bvModal.hide('edit-post-modal')
                            this.resetToDefault()
                        })
                        .catch(err => {
                            console.log(err)
                            this.loading = ''
                        })
                }
            },
            resetToDefault() {
                this.removeErrors()
                const moment = require('moment')
                this.form = {
                    pinboardId: this.pinboardId,
                    pinboardPostId: null,
                    travelTipId: null,
                    title: "",
                    description: "",
                    numberOfDaysMin: 1,
                    numberOfDaysMax: 10,
                    startDate: moment().format('YYYY-MM-DD'),
                    endDate: null,
                }
                this.filter.searchString = ''
                this.ammountOfVisibleTravelTips = VISIBLE_TRAVEL_TIPS_STEP
                this.isMoreToShow = true
                this.slider.value = [1, 10]
                this.slider.options.max = 10
                this.form.travelTipId = null
                this.chosenTravelTip = null
                this.isTravelTipListVisible = false
                this.chosenTravelTip = null
            },
            formatTooltip(value) {
                return this.$tc("general.dayAmmount", value)
            },
            formatDate(date, isWithYear) {
                let moment = require('moment')
                const formatWithYear = "D. M. YYYY"
                return moment(date).format(isWithYear ? formatWithYear : "D. M.")
            },
        },

        computed: {
            currentUserId() {
                return this.$store.state.currentUserId
            },
            currentUserRole() {
                return this.$store.state.currentUserRole
            },
            showMergeTooltip() {
                return this.slider.value[1] - this.slider.value[0] < 2
            },
            filteredTravelTips() {
                if (!this.travelTipsActual) return
                if (this.filter.searchString || this.form.travelTipId) {
                    return this.travelTipsActual.filter(tip => {
                        if (this.filter.searchString && !sharedUtils.removeAccents(tip.title).toUpperCase().includes(sharedUtils.removeAccents(this.filter.searchString).toUpperCase())) {
                            return false
                        } else if (tip.id == this.form.travelTipId) {
                            return false
                        }
                        return true
                    })
                } else {
                    return [...this.travelTipsActual]
                }
            },
            visibleTravelTips() {
                if (!this.filteredTravelTips) return []
                let travelTips = [...this.filteredTravelTips]
                return travelTips.slice(0, this.ammountOfVisibleTravelTips)
            },
        },

        apollo: {
            pinboard: {
                query: PINBOARD_BY_ID,
                variables: function() {
                    return {
                        pinboardId: this.pinboardId
                    }
                }
            },
            pinboardPosts: {
                query: PINBOARD_POSTS,
                variables: function() {
                    return {
                        pinboardId: this.pinboardId
                    }
                },
                // skip() {
                //     return !this.pinboardId
                // },
                update(data) {
                    return data.pinboardPosts.map(post => {
                        return {
                            ...post,
                            travelTip: post.travelTip ? assignTravelTipLocalizationProperties(post.travelTip) : null
                        }
                    })
                },
                fetchPolicy: 'cache-and-network',
            },
            travelTipsActual: {
                query: TRAVEL_TIPS_PINBOARD,

                variables: function () {
                    return {
                        filter: null,
                        pageSize: null                    }
                },
                update(data) {
                    return data.travelTipsActual.map(travelTip => assignTravelTipLocalizationProperties(travelTip))
                },
                result: function({data}) {
                    this.isMoreToShow = data.travelTipsActual.length > this.ammountOfVisibleTravelTips
                },
                error (error) {
                    console.log('We\'ve got an error!', error)
                }
            },
        }
    }

</script>

<style lang="scss">
    @import '@/scss/variables';

    .vue-slider-rail {
        background-color: $tr-gray !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .vue-slider-process {
        background-color: $tr-green !important;
        height: 4px !important;
        margin-top: -1px;
    }

    .vue-slider-dot-handle {
        background-color: #000 !important;
        box-shadow: none !important;
    }

    .vue-slider-dot-tooltip {
        visibility: visible !important;
    }

    .vue-slider-dot-tooltip-inner {
        border-color: transparent !important;
        background-color: transparent !important;
        color: $tr-black !important;
        font-size: 16px !important;
        font-weight: 500 !important;
    }

    .vue-slider-dot-tooltip-inner::after {
        display: none !important;
    }

    .vue-slider-custom-merge-tooltip {
        position: absolute;
        left: 50%;
        bottom: -20px;
    }
</style>

<style lang="scss" scoped>
    @import '@/scss/variables';

    a:hover {
        color: $tr-black;

        p {
            text-decoration: underline
        }
    }

    .chosen-travel-tip p, .travel-tip p, .travel-tip-card p {
        margin: 0;
        line-height: 20px;
    }

    .delete-modal-container {
        padding-inline: 30px;
        padding-block: 30px;

        .buttons-container {
            display: flex;
            flex-wrap: wrap;
            column-gap: 40px;
            row-gap: 20px;
        }

        @media (max-width: 576px) {
            padding-inline: 0;
        }
    }

    %tr-container-big {
        padding-inline: 10px;

        @media (min-width: 576px) {
            padding-inline: 40px;
        }
        @media (min-width: 768px) {
            padding-inline: 60px;
        }
        @media (min-width: 992px) {
            padding-inline: 100px;
        }
    }

    .number-of-days-title {
        font-size: 20px;
        margin-bottom: 10px;
    }
    .day-slider {
        margin-bottom: 40px;
        margin-right: 40px;
        position: relative;
        
        .vue-slider {
            padding-inline: 20px !important;
        }

        .button-set-more-days {
            position: absolute;
            left: 100%;
            margin-left: 10px;
            top: 38px;
            width: 0px;
            width: 0px;
            height: 0px;
            border-style: solid;
            border-width: 15px 0 15px 26.0px;
            border-color: transparent transparent transparent $tr-black;
        }
    }

    main {
            min-height: 700px;
            padding-block: 90px 60px;
        }

    .search-icon-container {
        display: flex;

        .search-icon {
            margin-left: auto;
            margin-bottom: 40px;
        }
    }


    .pinboard {
        position: relative;
        @extend %tr-container-big;
        width: 100%;
        padding-block: 60px 5px;
        background-color: $tr-white;
        display: flex;

        .title {
            font-size: 25px;
            width: 100%;
            text-align: center;

            @media (max-width: 768px) {
                font-size: 20px;
            }
        }

        .picture-background {
            position: absolute;
            width: 80px;
            height: 80px;
            background-color: $tr-green;
            top: -40px;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 50%;

            .picture {
                width: 80px;
                height: 80px;
                padding: 15px;
            }
        }
    }
    
    .travel-tip-title {
        margin-bottom: 6px !important;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .travel-tip-countries {
        color: $tr-gray;
        text-wrap: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

        .posts-container {
            display: flex;
            flex-direction: column;
            margin-top: 30px;
            max-width: 760px;
            margin-inline: auto;
            gap: 30px;

            .no-results {
                text-align: center
            }

            .post {
                @extend %tr-container-big;
                background-color: $tr-white;
                padding-block: 30px;

                .picture {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40px;
                    height: 40px;
                    aspect-ratio: 1;
                    background-color: $tr-green;
                    border-radius: 50%;
                    margin-right: 20px;
                }

                p {
                    margin-bottom: 0;
                }

                .username {

                }

                .created-at {
                    margin-left: auto;
                    font-size: 14px;
                    color: $tr-gray;
                    white-space: nowrap;
                }

                .main-content {
                    border-top: 1px solid $tr-light-gray;
                    border-bottom: 1px solid $tr-light-gray;
                    margin-top: 15px;
                    padding-block: 20px 30px;
                    word-break: break-word;

                    .title {
                        font-size: 25px;
                        margin-bottom: 8px;
                    }

                    .description {
                        white-space: pre-wrap;
                    }
                }

                .travel-tip {
                    display: flex;
                    gap: 20px;
                    padding-block: 15px;
                    border-bottom: 1px solid $tr-light-gray;
                }

                .additional-info-container {
                    padding-top: 20px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    p {
                        color: $tr-gray;
                    }

                    .day-range {
                        margin-right: 20px;
                    }

                    .buttons {
                        margin-left: auto;

                        button, a {
                            height: 40px;
                            width: 40px;
                        }   

                        a {
                            display: flex;
                            .icon-send {
                                margin: auto;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }

        .create-pinboard-post-button {
            width: 80px;
            height: 80px;
            border: none;
            appearance: none;
            background-color: $tr-green;
            border-radius: 50%;
            position: sticky;
            bottom: 40px;
            margin-left: auto;
            margin-top: 40px;
            padding: 28px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        form {
            margin-inline: auto;
            max-width: 700px;
            padding-block: 30px;
            display: flex;
            flex-direction: column;
            gap: 30px;

            @media (max-width: 576px) {
                padding-inline: 0;
            }

            .form-group {
                gap: 40px;
                display: flex;
                flex-wrap: wrap;
                margin: 0;
            }

            .buttons-container {
                display: flex;
                flex-wrap: wrap;
                column-gap: 40px;
                row-gap: 20px;

                button {
                    max-width: 330px;
                }
            }
        }

        .chosen-travel-tip {
            display: flex;
            gap: 20px;
            padding-block: 15px;
            border-top: 1px solid $tr-light-gray;
            border-bottom: 1px solid $tr-light-gray;
            margin-bottom: 10px;
        }

        .travel-tip-info {
            margin-block: 10px;
            width: calc(100% - 120px);
            position: relative;
            padding-right: 10px;
        }

        .travel-tip-picture {
            aspect-ratio: 4/3;
            object-fit: cover;
            max-width: 100px;
        }

        .add-travel-tip-container {
            padding: 20px;
            border: 1px solid $tr-gray;
            position: relative;

            .button-hide-list {
                position: absolute;
                right: 20px;
                top: 20px;
            }

            input {
                height: 40px;
                border-radius: 0px;
                border: 1px solid $tr-gray;
                max-width: 330px;
                width: 100%;
                margin-bottom: 20px;
                padding: 5px 10px;
            }
            
            .choose-travel-tip {
                font-size: 20px;
                margin-bottom: 20px;
            }
            .travel-tip-list {
                row-gap: 20px;
                column-gap: 30px;
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));

                .travel-tip-card {
                    display: flex;
                    gap: 20px;
                    max-width: 335px;
                    width: 100%;
                    background-color: $tr-white-alt;
                    cursor: pointer;

                    @media (max-width: 630px) {
                        max-width: 100%;
                    }

                    &:hover {
                        outline: 1px solid $tr-gray;
                        
                    }
    
                    
                    
                }

                .travel-tip-title {
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    position: relative;
                    word-break: break-word;
                    z-index: 4;

                    &::after {
                        display: none;
                        position: absolute;
                        left: -8px;
                        top: -8px;
                        background-color: $tr-white-alt;
                        content: attr(data-title);
                        width: calc(100% + 16px);
                        padding: 8px;
                        height: fit-content;
                        z-index: 2;
                    }

                    &:hover {
                        overflow: visible;
                        z-index: 5;

                        &::after {
                            display: block;
                        }
                    }
                }
            }

            .show-more-button {
                display: block;
                max-height: 40px;
                max-width: 200px;
                margin-top: 20px;
                margin-inline: auto;
            }
        }
</style>