import gql from "graphql-tag";

export const CREATE_PINBOARD_POST = gql`
    mutation createPinboardPost($pinboardId: Int!, $travelTipId: Int, $title: String!, $description: String, $numberOfDaysMin: Int!, $numberOfDaysMax: Int!, $startDate: Date!, $endDate: Date!) {
        createPinboardPost(pinboardId: $pinboardId, travelTipId: $travelTipId, title: $title, description: $description, numberOfDaysMin: $numberOfDaysMin, numberOfDaysMax: $numberOfDaysMax, startDate: $startDate, endDate: $endDate) {
            id
        }
}`
export const UPDATE_PINBOARD_POST = gql`
    mutation updatePinboardPost($pinboardPostId: Int!, $travelTipId: Int, $title: String, $description: String, $numberOfDaysMin: Int, $numberOfDaysMax: Int, $startDate: Date, $endDate: Date) {
        updatePinboardPost(pinboardPostId: $pinboardPostId, travelTipId: $travelTipId, title: $title, description: $description, numberOfDaysMin: $numberOfDaysMin, numberOfDaysMax: $numberOfDaysMax, startDate: $startDate, endDate: $endDate) {
            id
    }
}`
export const DELETE_PINBOARD_POST = gql`
    mutation deletePinboardPost($pinboardPostId: Int!) {
    deletePinboardPost(pinboardPostId: $pinboardPostId)
}`
